import type { RouteLocationNormalizedGeneric } from 'vue-router';
const { isTherapistAuthenticated } = useAuthTherapistStorage();

export default defineNuxtRouteMiddleware(
   async (
      to: RouteLocationNormalizedGeneric,
      from: RouteLocationNormalizedGeneric
   ) => {
      if (isTherapistAuthenticated?.value) {
         if (
            to.name == 'auth-therapist-login' ||
            to.name == 'auth-therapist-activate-token'
         ) {
            if (from.name && to.name !== from.name) {
               return;
            } else {
               return navigateTo({ name: 'therapist-booking' });
            }
         }
      } else {
         if (
            to.name == 'auth-therapist-login' ||
            to.name == 'auth-therapist-activate-token'
         ) {
            return;
         }
         return navigateTo({ name: 'auth-therapist-login' });
      }
   }
);
